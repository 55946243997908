<template>
  <ion-page>
    <ion-content :scroll-events="true">
      <ion-grid fixed>
        <ion-row class="ion-margin-top">
          <ion-col
            class="ion-align-self-center ion-margin-top"
            size-md="6"
            size="12"
          >
            <ion-card>
              <ion-card-header class="ion-margin-top">
                <ion-card-title class="xLargeFont">Mis Datos</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <v-form class="ion-margin" @submit="submit">
                  <ion-item>
                    <ion-label position="floating">Apellido</ion-label>
                    <ion-input
                      name="apellido"
                      v-model="apellido"
                      autocapitalize="words"
                      type="text"
                      inputmode="text"
                      autocomplete="family-name"
                      :disabled="isAapresidUser"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">
                      Nombre
                    </ion-label>
                    <ion-input
                      v-model="nombre"
                      autocapitalize="words"
                      type="text"
                      inputmode="text"
                      autocomplete="name"
                      :disabled="isAapresidUser"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Email</ion-label>
                    <b>
                      <ion-input
                        name="email"
                        v-model="email"
                        type="text"
                        inputmode="text"
                        readonly="true"
                        class="largeFont"
                      ></ion-input>
                    </b>
                  </ion-item>
                  <ion-item class="ion-margin-top" :disabled="isAapresidUser">
                    <ion-label position="floating">Provincia</ion-label>
                    <ion-select
                      :value="provincia"
                      @ionChange="provincia = $event.target.value"
                    >
                      <ion-select-option
                        v-for="p in provincias"
                        :value="p"
                        :key="p"
                      >
                        {{ p }}
                      </ion-select-option>
                    </ion-select>
                  </ion-item>
                  <ion-item
                    v-if="!displaySearchbar"
                    @click="switchDisplaySearchbar()"
                    :disabled="isAapresidUser"
                  >
                    <ion-label position="floating">Localidad</ion-label>
                    <ion-input
                      :value="capitalizar(localidad.nombre)"
                      autocapitalize="words"
                      type="text"
                      inputmode="text"
                      readonly="true"
                    ></ion-input>
                  </ion-item>
                  <ion-row v-if="displaySearchbar">
                    <ion-col style="margin-left: 4px">
                      <ion-label>
                        Localidad
                      </ion-label>
                    </ion-col>
                  </ion-row>
                  <ion-searchbar
                    placeholder="Localidad"
                    @ionChange="filtroLocalidad = $event.target.value"
                    :value="capitalizar(localidad.nombre)"
                    v-if="displaySearchbar"
                  />
                  <div
                    v-if="displaySearchbar"
                    style="margin-left: 56px; margin-right: 8px;"
                  >
                    <ion-list
                      lines="full"
                      v-for="l in localidades"
                      :value="l"
                      :key="l._id"
                    >
                      <ion-item
                        class="reduceLateralMargin"
                        button="true"
                        type="button"
                        @click="selectLocalidad(l)"
                      >
                        <ion-label
                          style="white-space: normal; font-size: 12px !important"
                        >
                          {{ capitalizar(l.nombre) }}
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </div>
                  <div
                    v-if="
                      displaySearchbar && localidades && localidades.length > 0
                    "
                    style="background-color: rgba(var(--ion-color-medium-rgb), 0.375); 
                    padding-top: 4px; margin-left: 56px; margin-right: 8px;"
                  ></div>
                  <ion-item>
                    <ion-label position="floating">Regional</ion-label>
                    <ion-select
                      :value="regional"
                      @ionChange="regional = $event.target.value"
                    >
                      <ion-select-option
                        v-for="reg in regionales"
                        :value="reg._id"
                        :key="reg._id"
                      >
                        {{ reg.nombre }}
                      </ion-select-option>
                    </ion-select>
                  </ion-item>
                  <ion-item v-show="error">
                    <ion-text color="danger">
                      <h4>{{ error1 }}</h4>
                    </ion-text>
                  </ion-item>
                  <ion-row class="ion-float-right">
                    <ion-col size="auto">
                      <ion-button
                        fill="outline"
                        class="ion-margin-top fontSmallSize"
                        color="medium"
                        @click="cancel"
                      >
                        Cancelar
                      </ion-button>
                    </ion-col>
                    <ion-col size="auto">
                      <ion-button class="ion-margin-top" type="submit">
                        Aceptar
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </v-form>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import capitalizeWords from "@/utils/capitalizeWords.js";
import * as V from "vee-validate/dist/vee-validate";
import { mapState, mapActions } from "vuex";
import {
  IonInput,
  IonLabel,
  IonPage,
  IonText,
  IonList,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonCardHeader,
  IonItem,
  IonCard,
  IonCardTitle,
  IonContent,
  IonRow,
  IonCardContent,
  IonCol,
  IonGrid,
  alertController,
  IonButton
} from "@ionic/vue";
import { Regionales, Provincias } from "@/api";
import http from "@/api/http";
//import isEmail from "validator/lib/isEmail";

export default {
  components: {
    IonInput,
    IonText,
    IonSearchbar,
    IonList,
    IonSelect,
    IonCardHeader,
    IonSelectOption,
    IonLabel,
    IonItem,
    IonPage,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonContent,
    IonRow,
    IonCol,
    IonGrid,
    IonButton,
    VForm: V.Form
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    persona: { type: Object, default: () => ({}) }
  },

  data: () => ({
    reset: false,
    nombre: "",
    apellido: "",
    provincia: "",
    error1: "",
    email: "",
    localidad: {},
    filtroLocalidad: "",
    regional: {},
    localidades: [],
    regionales: [],
    provincias: [],
    displaySearchbar: false
  }),

  async mounted() {
    await this.getMe();
    await this.dataSync();
    this.fillFields();
  },

  computed: {
    ...mapState(["user"]),
    editing() {
      return !!this.persona._id;
    },
    isAapresidUser() {
      return this.user.isAapresidUser ? this.user.isAapresidUser : false;
    }
  },

  watch: {
    success(val) {
      if (val) {
        this.cleaner();
      }
    },
    persona() {
      this.cleaner();
      if (this.editing) {
        this.fillFields();
      }
    },
    provincia() {
      this.localidad = {};
    },
    async filtroLocalidad(val) {
      if (val !== "" && val.length >= 3) {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
          await this.getLocalidades();
        }, 500);
      } else {
        this.localidades = [];
      }
    },
    localidad() {
      this.displaySearchbar = false;
      this.filtroLocalidad = "";
    }
  },

  created() {
    if (this.editing) {
      this.fillFields();
    }
  },

  methods: {
    ...mapActions(["getMe"]),
    cleaner() {
      this.reset = !this.reset;
      this.nombre = "";
      this.apellido = "";
      this.email = "";
      this.localidad = {};
      this.provincia = "";
      this.regional = {};
      //this.error = "";
    },

    capitalizar(str) {
      return capitalizeWords(str);
    },

    switchDisplaySearchbar() {
      this.displaySearchbar = !this.displaySearchbar;
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    async dataSync() {
      await this.getRegionales();
      await this.getProvincias();
    },

    async getRegionales() {
      const { data, error } = await Regionales.get({ sort: { nombre: 1 } });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo regionales.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      this.regionales = data;
    },

    async getLocalidades() {
      const { data, error } = await http.get(
        "/api/get-localidades-por-nombre-provincia",
        {
          params: {
            provincia: this.provincia.toString(),
            filtroLocalidad: this.filtroLocalidad.toString().toLowerCase()
          }
        }
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo localidades.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      this.localidades = data;
    },

    async getProvincias() {
      const { data, error } = await Provincias.get({ sort: { nombre: 1 } });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo provincias.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      this.provincias = data.map(p => capitalizeWords(p.nombre));
    },

    selectLocalidad(loc) {
      this.localidad = loc;
      this.filtroLocalidad = "";
    },

    async submit() {
      this.$emit("update:success", false);
      let error = "";
      if (!this.nombre) {
        error += "<li>Complete el campo <b>Denominación</b>." + `<br/></li>`;
      }
      if (!this.apellido) {
        error += "<li>Complete el campo <b>Apellido</b>." + `<br/></li>`;
      }
      if (!this.provincia) {
        error += "<li>Elija una <b>Provincia</b>." + `<br/></li>`;
      }
      if (!error) {
        this.$emit("submit", {
          persona: {
            _id: this.persona._id,
            nombre: this.nombre,
            apellido: this.apellido,
            email: this.email,
            provincia: this.provincia,
            localidad: this.localidad,
            regional: this.regional
          }
        });
      } else {
        const alert = await alertController.create({
          header: "Error al editar los datos de la persona",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      //}
    },

    fillFields() {
      this.nombre = capitalizeWords(this.persona.nombre);
      this.apellido = capitalizeWords(this.persona.apellido);
      this.email = this.persona.email || "";
      setTimeout(() => {
        this.provincia = this.persona.provincia || "";
        this.localidad = this.persona.localidad || "";
        this.regional = this.persona.regional || "";
      }, 500);
    },

    isRequired(value) {
      return value ? true : "This field is required";
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

ion-item {
  --pading-start: 8px;
}

ion-list {
  background-color: rgba(var(--ion-color-medium-rgb), 0.375);
  padding-top: 4px;
  padding-bottom: 0px;
}

.reduceLateralMargin {
  margin-left: 4px;
  margin-right: 4px;
}

.fontSmallSize {
  font-size: small;
}

.marginBottom6px {
  margin-bottom: 6px;
}

.largeFont {
  font-size: large;
}

.xLargeFont {
  font-size: x-large;
}
</style>
