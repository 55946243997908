<template>
  <ion-page>
    <ion-content :scroll-events="true">
      <div id="container" class="marginBottom4px">
        <EditDatosForm
          :persona="persona"
          :error="error"
          @submit="submitEdit"
          @cancel="closeEdit"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, alertController } from "@ionic/vue";
import EditDatosForm from "../../components/EditDatosForm.vue";
import { mapActions, mapState } from "vuex";
//import http from "@/api/http";
import { Persona } from "@/api";

export default {
  components: {
    IonContent,
    IonPage,
    EditDatosForm
  },
  data: () => ({
    success: null,
    error: ""
  }),
  computed: {
    ...mapState(["user"])
  },
  async mounted() {
    await this.dataSync();
    await this.getMe();
  },
  methods: {
    ...mapActions(["getMe"]),
    async dataSync() {
      this.persona = this.user || {
        nombre: this.user.nombre,
        apellido: this.user.apellido,
        email: this.user.email,
        provincia: this.user.provincia,
        localidad: this.user.localidad,
        regional: this.user.regional
      };
    },

    closeEdit() {
      this.$router.push({ name: "home" });
    },

    async submitEdit({ persona }) {
      this.error = "";
      try {
        const { error } = await Persona.save(persona);
        if (error) {
          this.error = error;
        } else {
          this.success = true;
          const alert = await alertController.create({
            header: "Productor",
            message: "Datos del productor actualizados.",
            buttons: [
              {
                text: "Aceptar",
                handler: async () => {
                  await this.getMe();
                  this.$router.push({ name: "persona-lotes" });
                }
              }
            ]
          });
          alert.present();
        }
      } catch (err) {
        this.error = "El email ya está registrado.";
      }
    }
  }
};
</script>

<style scoped>
.leftMargin2vh {
  margin-left: 2vh;
}

.margen8pxArriba {
  margin-top: 8px;
}

.marginBottom4px {
  margin-bottom: 4px;
}

@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
}
</style>
